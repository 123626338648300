import {gMap as gm} from './googleMaps'
import defaultMarker from '../../../images/marker.svg'

var mapSingle = document.querySelector('.property .map'),
    fullscreen = document.querySelector('.property .fullscreen'),
    zoomOut = document.querySelector('.property .minus'),
    zoomIn = document.querySelector('.property .plus'),
    zoom = 11,
    map

if (mapSingle !== null) {
    
    var configSingle = {
        map: map,
        markerIcon: defaultMarker,
        mapElement: '.property .map',
        lat: Number(mapSingle.dataset.lat),
        lng: Number(mapSingle.dataset.lng),
        zoom: zoom,
        fullscreen: fullscreen,
        zoomOut: zoomOut,
        zoomIn: zoomIn,
        disableDefaultUI: true,
        styles: gm.setMapStyles(),
        hasInfoWindows: false,
    }
    
    gm.initMap(configSingle)
}