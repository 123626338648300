import {gMap as gm} from './googleMaps'
import defaultMarker from '../../../images/marker.svg'
import $ from 'jquery'

var propertiesMap = document.querySelector('.properties-map'),
    mobile = window.matchMedia( "(max-width: 1023px)" ),
    geocoder = new google.maps.Geocoder(),
    fullscreen = document.querySelector('.fullscreen-container .fullscreen'),
    zoomOut = document.querySelector('.zoom-control .minus'),
    zoomIn = document.querySelector('.zoom-control .plus'),
    mapCenter,
    map

if (propertiesMap !== null) {
    propertyGetAll()
}

function propertyGetAll() {
    // Data to send to the server for query
    var data = {
        action : 'property_search_init',
    }
    
    $.ajax({
        url : ajax_object.ajax_url,
        data : data,
        success : (response) => {
            var locations = [],
                zoom = 9,
                city

            if (response) {
                for(var i = 0 ;  i < response.length ; i++) {
                    var property = response[i]

                    // Add location to the array of locations
                    locations.push({
                        id: property.id,
                        lat : Number(property.latitude),
                        lng: Number(property.longitude),
                        title: property.title,
                        location: property.location,
                        infoWindow: infoWindowHTML(property),
                        marker: property.marker
                    })
                }
            }

            // Get location for map centering
            city = 'Kansas City'

            var config = {
                map: map,
                mapElement: '.properties-map .map',
                markerIcon: defaultMarker,
                locations: locations,
                zoom: zoom,
                city: city,
                fullscreen: fullscreen,
                zoomOut: zoomOut,
                zoomIn: zoomIn,
                disableDefaultUI: true,
                styles: gm.setMapStyles(),
                hasInfoWindows: true,
            }

            gm.initMap(config)

        },
        error: (error) => {
            console.log(error)
        }
    })
}


function infoWindowHTML(property) {
    return `
        <li class="property-infowindow">
            <div class="featured-image">
                <div class="type">For ${property.availability}</div>
                <img src="${property.featured_image}" alt="Featured Property Image">
            </div>
            <div class="content">
                <div class="square-feet">
                    <span>${property.square_feet}sqft.</span>
                </div>
                <div class="title">${property.title}</div>
                <div class="address">${property.address}</div>
                <a href="${property.permalink}">View Property</a>
            </div>
        </div>
    `
}