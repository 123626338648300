const bios = document.querySelectorAll('.bio-single')


if (bios !== null) {

    bios.forEach((obj, index) => {
       var modalTrigger = obj.querySelector('.modal-trigger')

        if (modalTrigger !== null) {
            modalTrigger.addEventListener('click', (e) => {
                e.preventDefault()
                document.querySelector('.bio-modal-' + index).classList.add('bio-open')
            })
    
            document.querySelector('.bio-modal-' + index + ' .close').addEventListener('click', (e) => {
                e.preventDefault()
                document.querySelector('.bio-modal-' + index).classList.remove('bio-open')
            })
        }

    })
}