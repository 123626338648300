import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

const testimonials = document.querySelectorAll('.testimonial-slider .testimonial-container')

document.addEventListener("DOMContentLoaded", function () {
    testimonials.forEach((obj, index) => {
        var testimonial = new Swiper(obj, {
            spaceBetween: 0,
            loop: true,
            loopedSlides: 1,
            autoplay: {
                delay: 5000
            },
        
            navigation: {
                nextEl: '.testimonial-slider-' + index + ' .next',
                prevEl: '.testimonial-slider-' + index + ' .prev',
            },
        })
    }) 
})


