import globals from './globals'
import {gMap as gm} from './googleMaps'
import $ from 'jquery'
import simpleLoadMore from 'simple-load-more'
import defaultMarker from '../../../images/marker.svg'

var properties = document.querySelector('.properties'),
    propertySearch = document.querySelector('.property-search'),
    listViewButton = document.querySelector('.layout-controls .list-view'),
    mapViewButton = document.querySelector('.layout-controls .map-view'),
    mobile = window.matchMedia( "(max-width: 1023px)" ),
    isListView = false,
    listViewCount,
    loadMoreCount,
    map


if (propertySearch !== null) {

    //=============================================================================
    // PROPERTY SEARCH
    //=============================================================================
    // Init global settings
    initGlobalSettings()

    // Init default search of all properties
    propertySearchInit()

    // Filter on submit
    propertySearch.querySelector('form').addEventListener('submit', (e) => {
        e.preventDefault()
        e.stopPropagation()

        propertySearchFilter()
    })
    // Use custom mobile search button to trigger submit
    if (mobile.matches) {
        document.querySelector('.layout-controls .search').addEventListener('click', (e) => {
            e.preventDefault()
            propertySearch.querySelector('form button').click()
        })
    }



    //=============================================================================
    // PROPERTY LIST VIEWS
    //=============================================================================
    // List View
    listViewButton.addEventListener('click', (e) => {
        e.preventDefault()

        document.querySelectorAll('.control').forEach((obj, index) => {
            obj.classList.remove('is-selected')
        })
        listViewButton.classList.add('is-selected')

        properties.classList.remove('map-view')
        properties.classList.add('list-view')

        // Set Scroll Position to first listing
        globals.setScrollPosition(document.querySelector('.property-list-single'), 320)

        isListView = true
    })
    if (mobile.matches) {
        properties.classList.remove('map-view')
        properties.classList.add('list-view')

        mapViewButton.classList.remove('is-selected')
        listViewButton.classList.add('is-selected')
    }

    // Map View
    mapViewButton.addEventListener('click', (e) => {
        e.preventDefault()

        document.querySelectorAll('.control').forEach((obj, index) => {
            obj.classList.remove('is-selected')
        })
        mapViewButton.classList.add('is-selected')

        properties.classList.remove('list-view')
        properties.classList.add('map-view')

        // Set Scroll Position to first listing
        globals.setScrollPosition(document.querySelector('.property-list-single'), 320)

        isListView = false
    })


    //=============================================================================
    // PROPERTY FUNCTIONS
    //=============================================================================
    function propertySearchInit() {
        // Data to send to the server for query
        var data = {
            action : 'property_search_init',
        }
        initAjax(data, 9, true)
    }


    function propertySearchFilter() {
        if (propertySearch.querySelector('#type').value.length !== 0 ) {
            var type = propertySearch.querySelector('#type').value
        }
        if (propertySearch.querySelector('#location').value.length !== 0 ) {
            var location = propertySearch.querySelector('#location').value
        }
        if (propertySearch.querySelector('#availability').value.length !== 0 ) {
            var availability = propertySearch.querySelector('#availability').value
        } 
         
        
        // Data to send to the server for query
        var data = {
            action : 'property_search_filter',
            type : type,
            location : location,
            availability: availability
        }
        initAjax(data, 9, false)
    }


    function initGlobalSettings() {
        var data = {
            action : 'property_settings'
        }

        $.ajax({
            url : ajax_object.ajax_url,
            data : data,
            success : (response) => {
                listViewCount = response.listings_view_count
                loadMoreCount = response.listings_view_more_count
            }
        })
    }


    function initAjax(data, defaultZoom, defaultCenter) {
        $.ajax({
            url : ajax_object.ajax_url,
            data : data,
            success : (response) => {
                var propertyList = document.querySelector('.property-list ul'),
                    locations = [],
                    city

                // Clear list
                propertyList.innerHTML = ''

                if (response) {
                    for(var i = 0 ;  i < response.length ; i++) {
                        var property = response[i]
                    
                        // Append property to list
                        propertyList.innerHTML += listHTML(property, 'property-list-main')

                        // Add location to the array of locations
                        locations.push({
                            id: property.id,
                            lat : Number(property.latitude),
                            lng: Number(property.longitude),
                            title: property.title,
                            location: property.location,
                            infoWindow: listHTML(property, 'property-list-infoWindow'),
                            marker: property.marker
                        })

                    }
                } else {
                    // No properties found
                    propertyList.innerHTML += listNotFoundHTML()   
                    locations.push({location: 'None Found'})
                }

                console.log(locations)

                // Get location for map centering
                city =  'Kansas City'

                // Add load more functionality of property listings
                // Pass global variable for many listings intially load 
                initLoadMore() 

                // Init map with our locations set
                initMap(city, defaultZoom, locations, defaultMarker)
            },
            error: (error) => {
                console.log(error)
            }
        })
    }


    function listHTML(property, $class) {
        if ($class === 'property-list-main') {
            return `
            <li class="property-list-single property-list-single-${property.id} ${$class}" 
                data-lat="${property.longitude}" 
                data-lng="${property.latitude}"
                >
                <div class="featured-image">
                    <div class="type">For ${property.availability}</div>
                    <img src="${property.featured_image}" alt="Featured Property Image">
                </div>
                <div class="content">
                    <div class="content-left">
                        <div class="title">${property.title}</div>
                        <div class="address">${property.address}</div>
                    </div>
                    <div class="content-right">
                        <div class="square-feet">
                            <span>${property.square_feet}sqft.</span>
                        </div>
                        <a class="btn-underline" href="${property.permalink}">View Property</a>
                    </div>
                </div>
            </div>
        `
        } else {
            return `
            <li class="property-list-single ${$class}">
                <div class="featured-image">
                    <div class="type">For ${property.availability}</div>
                    <img src="${property.featured_image}" alt="Featured Property Image">
                </div>
                <div class="content">
                    <div class="square-feet">
                        <span>${property.square_feet}sqft.</span>
                    </div>
                    <div class="title">${property.title}</div>
                    <div class="address">${property.address}</div>
                    <a href="${property.permalink}">View Property</a>
                </div>
            </div>
        `
        }
    }


    function listNotFoundHTML() {
        return `
            <div class="property-not-found">
                <span class="heading-2">Sorry, no properties were found</span>
            </div>
        `
    }


    function initLoadMore() {
        var loadMoreButton = document.querySelector('.load-more'),
            spinner = document.querySelector('.lds-ellipsis')


        // set the count of more listings with global variable
        // show more listings  
        var listings = $('.property-list-single').slice(0, listViewCount)
        listings.show()

        // Scroll to first listing
        // globals.setScrollPosition(listings, 320)

        $(loadMoreButton).on('click', (e) => { // click event for load more
            e.preventDefault()

            var hiddenListings = $('.property-list-single:hidden').slice(0, loadMoreCount) // select next 10 hidden divs and show them
            $(hiddenListings).show()

            // Set scroll position to top of first new listing
            globals.setScrollPosition(hiddenListings, 320)


            if ($('.property-list-single:hidden').length == 0) { // check if any hidden divs still exist
                $(loadMoreButton).hide()
            }
        })

        if ($('.property-list-single:hidden').length == 0) { // check if any hidden divs still exist
            $(loadMoreButton).hide()
        }
    }


    function initMap(city, zoom, locations) {
        // Get city cordinates for centering the map
        var geocoder = new google.maps.Geocoder(),
            fullscreen = document.querySelector('.fullscreen-container .fullscreen'),
            zoomOut = document.querySelector('.zoom-control .minus'),
            zoomIn = document.querySelector('.zoom-control .plus'),
            mapCenter

        geocoder.geocode( { 'address': city}, function(results, status) {
            if (status == 'OK') {
                map.setCenter(results[0].geometry.location);
            } else {
            alert('Geocode was not successful for the following reason: ' + status);
            }
        })

        map = new google.maps.Map(document.querySelector('.property-map .google-map'), {
            zoom: zoom,
            disableDefaultUI: true,
            styles: gm.setMapStyles()
        })
        setMarkers(map)
        setControls()


        function setControls() {
            fullscreen.addEventListener('click', (e) => {
                e.preventDefault()
                var map = document.querySelector(".gm-style");
                map.requestFullscreen();
            })
            zoomOut.addEventListener('click', (e) => {
                e.preventDefault()
                zoom -= 1
                map.setZoom(zoom)
            })
            zoomIn.addEventListener('click', (e) => {
                e.preventDefault()
                zoom += 1
                map.setZoom(zoom)
            })
        }



        function setMarkers(map) {
            var infowindows = []

            // Loop through all locations and set marker
            for (let i = 0; i < locations.length; i++) {
            const location = locations[i];
            
            const infowindow = new google.maps.InfoWindow({
                content: location.infoWindow,
            })
            
            // Add all infowindows for closing all on list click  
            infowindows.push(infowindow)

            const marker = new google.maps.Marker({
                position: { lat: location.lat, lng: location.lng },
                map,
                title: location.title,
                icon: {
                    url: defaultMarker,
                    size: new google.maps.Size(40, 58),
                }    
            })

            if (i === 0) {
                infowindow.open(map, marker)
            }

            setPropertyEventListeners(map, marker, infowindow, infowindows, location)
            }
        }


        function setPropertyEventListeners(map, marker, infowindow, infowindows, location) {
            var propertyListSingle = document.querySelector('.property-list-single-' + location.id)

            propertyListSingle.addEventListener('click', (e) => {
                if (isListView) {
                    e.preventDefault()
                    window.location = e.currentTarget.querySelector('a').getAttribute('href')
                } else {
                    closeAllInfoWindows(infowindows)
                    setPropertyActive(map, marker, infowindow, location)
                }
            })
            
            // Map Events
            marker.addListener("click", () => {
                // Close all infowindows before opened new one
                closeAllInfoWindows(infowindows)

                infowindow.open(map, marker);
            })
        }


        function setPropertyActive(map, marker, infowindow, location) {
            map.setCenter({ lat: location.lat, lng: location.lng })
            map.setZoom(14)
            infowindow.open(map, marker);
        }
        

        function closeAllInfoWindows(infowindows) {
            infowindows.forEach((obj, index) => {
                obj.close()
            })
        }
    }

}