import {gMap as gm} from './googleMaps'
import defaultMarker from '../../../images/marker.svg'

var contactMap = document.querySelector('.contact .map'),
    fullscreen = document.querySelector('.contact .fullscreen'),
    zoomOut = document.querySelector('.contact .minus'),
    zoomIn = document.querySelector('.contact .plus'),
    zoom = 11,
    map

if (contactMap !== null) {
    
    var configSingle = {
        map: map,
        markerIcon: defaultMarker,
        mapElement: '.contact .map',
        lat: Number(contactMap.dataset.lat),
        lng: Number(contactMap.dataset.lng),
        zoom: zoom,
        fullscreen: fullscreen,
        zoomOut: zoomOut,
        zoomIn: zoomIn,
        disableDefaultUI: true,
        styles: gm.setMapStyles(),
        hasInfoWindows: false,
    }
    
    gm.initMap(configSingle)
}