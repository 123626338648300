// import main SASS file
import '../../styles/scss/style.scss'

// Import Foundation
import './init-foundation'

import '@fortawesome/fontawesome-free/css/all'

// Load JS modules to import at runtime
import './modules/globals'
import './modules/properties'
import './modules/lazyload'
import './modules/fancybox'
import './modules/ie'
import './modules/animations'
import './modules/subMenu'
import './modules/fullWidthSlider'
import './modules/select'
import './modules/googleMaps'
import './modules/propertyGallery'
import './modules/propertySingle'
import './modules/propertiesMap'
import './modules/splitImageContent'
import './modules/testimonialSlider'
import './modules/propertiesFeature'
import './modules/accordion'
import './modules/splitImageCallout'
import './modules/contentImageSlider'
import './modules/biosModal'
import './modules/contact'
import './modules/blog'
import './modules/featureProjects'