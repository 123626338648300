const splitImageCallout = document.querySelectorAll('.split-image-callout')


if (splitImageCallout !== null) {
    splitImageCallout.forEach((obj, index) => {
       var modalTrigger = obj.querySelector('.modal-trigger'),
           close = obj.querySelector('.close')

        if (modalTrigger !== null) {
            modalTrigger.addEventListener('click', (e) => {
                e.preventDefault()
                obj.querySelector('.bio-modal').classList.add('bio-open')
            })
    
            close.addEventListener('click', (e) => {
                e.preventDefault()
                obj.querySelector('.bio-modal').classList.remove('bio-open')
            })
        }

    })
}