import globals from './globals'

const post = document.querySelector('.post')

if (post !== null) {
    globals.infineteLoad({
        buttonEl: '.load-more',
        itemEl: '.post',
        initCount: 6,
        loadCount: 6,
    })
}
