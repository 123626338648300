import ScrollReveal from 'scrollreveal'

var lsAnimation = document.querySelector('.animation')

if (lsAnimation !== null) {
    ScrollReveal().reveal('.animation', {
        beforeReveal: (el) => {
            lsAnimation.classList.add('animation-start')
        },
    })
}