export const gMap = {

    initMap: (config) => {
        config.map = new google.maps.Map(document.querySelector(config.mapElement), {
            zoom: config.zoom,
            disableDefaultUI: config.disableDefaultUI,
            styles: config.styles
        })
        gMap.setMarkers(config)
        gMap.setControls(config)

        // Init map with our locations set
        if (config.geocoder !== undefined) {
            config.geocoder.geocode( { 'address': config.city}, function(results, status) {
                if (status == 'OK') {
                    config.map.setCenter(results[0].geometry.location);
                } else {
                alert('Geocode was not successful for the following reason: ' + status);
                }
            })
        }
    },

    setMarkers: (config) => {
        var infowindows = [],
            map = config.map

        // Loop through all locations and set marker
        if (config.locations !== undefined) {
            for (let i = 0; i < config.locations.length; i++) {
                const location = config.locations[i];
                
                const infowindow = new google.maps.InfoWindow({
                    content: location.infoWindow,
                })
                
                // Add all infowindows for closing all on list click  
                infowindows.push(infowindow)

                const marker = new google.maps.Marker({
                    position: { lat: location.lat, lng: location.lng },
                    map,
                    title: location.title,
                    icon: {
                        url: config.markerIcon,
                        size: new google.maps.Size(40, 58),
                    }    
                })

                if (config.hasInfoWindows) {
                    // list events **TODO**

                    // Open first infowindow for default
                    if (i === 0) {
                        // center if geocode is not set
                        if (config.geocoder === undefined)  map.setCenter({ lat: location.lat, lng: location.lng })
                        infowindow.open(map, marker)
                    }

                    // Map events
                    marker.addListener("click", () => {
                        // Class all active windows
                        gMap.closeAllInfoWindows(infowindows)

                        // Open active infowindow
                        gMap.setPropertyActive(config, location, infowindow, marker)
                    })
                }
            }
        } else {
            const marker = new google.maps.Marker({
                position: { lat: config.lat, lng: config.lng },
                map,
                icon: {
                    url: config.markerIcon,
                    size: new google.maps.Size(40, 58),
                }    
            })
            // Center single location on map
            config.map.setCenter({lat: config.lat, lng: config.lng})
        }
    },

    setMapStyles: () => {
        return [
            {
                "featureType": "all",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#f6f6f6"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": 36
                    },
                    {
                        "color": "#333333"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "all",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dedede"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e9e9e9"
                    },
                    {
                        "lightness": 17
                    }
                ]
            }
        ]
    },

    setControls: (config) => {
        config.fullscreen.addEventListener('click', (e) => {
            e.preventDefault()
            var map = document.querySelector(".gm-style");
            map.requestFullscreen();
        })
        config.zoomOut.addEventListener('click', (e) => {
            e.preventDefault()
            config.zoom -= 1
            config.map.setZoom(config.zoom)
        })
        config.zoomIn.addEventListener('click', (e) => {
            e.preventDefault()
            config.zoom += 1
            config.map.setZoom(config.zoom)
        })
    },

    closeAllInfoWindows: (infowindows) => {
        infowindows.forEach((obj, index) => {
            obj.close()
        })
    },

    setPropertyActive(config, location, infowindow, marker) {
        config.map.setCenter({ lat: location.lat, lng: location.lng })
        config.map.setZoom(14)
        infowindow.open(config.map, marker);
    }
}