import globals from './globals'
import anim from './animations'
import {ResizeObserver as Polyfill} from '@juggle/resize-observer'

var mobile = window.matchMedia( "(max-width: 1023px)" )

document.addEventListener("DOMContentLoaded", function () {
    var subMenuItems = document.querySelectorAll('.sub-menu .menu-item'),
        menuHasChildren = document.querySelectorAll('.menu-item-has-children'),
        defaultHeaderHeight = globals.getCSSVariable('--headerHeight'),
        logo = document.querySelector('.top-bar .logo')

    subMenuItems.forEach((obj, index) => {
        obj.querySelector('a').classList.add('sub-anchor')
    })

    menuHasChildren.forEach((obj, index) => {
        if (mobile.matches) {
            obj.querySelector('a').addEventListener('click', (e) => {
                e.preventDefault()
                e.stopPropagation()
                obj.classList.toggle('toggle-open')
            }, true)
        }

        // Adjust menu padding on resize
        const ResizeObserver = window.ResizeObserver || Polyfill
        const ro = new ResizeObserver((entries, observer) => {
            entries.forEach((entry) => {
                obj.querySelector('.sub-menu').style.paddingLeft = (globals.getElementPosition(obj).x / 2 - 25) + 'px'
            })
        })
        ro.observe(document.body); // Watch dimension changes on body

        obj.addEventListener('mouseover', (e) => {
            // globals.setCSSVariable('headerHeight', globals.getCSSVariable('headerHeightTransition'))
            obj.querySelector('.sub-menu').classList.add('fadeIn')
        })

        obj.addEventListener('mouseleave', (e) => {
            obj.querySelector('.sub-menu').classList.remove('fadeIn')
            // globals.setCSSVariable('headerHeight', globals.getCSSVariable(defaultHeaderHeight))
            obj.classList.remove('headerTransition')
        })

    })

    window.onscroll = function() {scrollFunction()};

    function scrollFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            globals.setCSSVariable('headerHeight', globals.getCSSVariable('headerHeightTransition'))
            logo.classList.add('shrink')
        } else if (document.body.scrollTop < 50 || document.documentElement.scrollTop < 50) {
            globals.setCSSVariable('headerHeight', globals.getCSSVariable(defaultHeaderHeight))
            logo.classList.remove('shrink')
        }
    }

})