import g from './globals'
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

if (g.elementInDom('.feature-projects')) {
    const projectSliders = document.querySelectorAll('.feature-projects .slider-container')

    projectSliders.forEach((obj, index) => {
        let isDisabled = obj.dataset.isDisabled
        let autoplay = (isDisabled == 'true') ? false : { delay: 5000 }
        let allowTouchMove = (isDisabled == 'true') ? false : true

        if (isDisabled == 'true') obj.classList.add('disable-swiper')

        new Swiper(obj, {
            direction: 'horizontal',
            loop: true,
            pagination: {
                el: obj.querySelector('.slider-pagination'),
                type: 'fraction',
            },
            allowTouchMove: allowTouchMove,
            autoplay: autoplay,
            navigation: {
                nextEl: obj.querySelector('.next'),
                prevEl: obj.querySelector('.prev'),
            },
        })
    })
}