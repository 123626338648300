import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'


document.addEventListener("DOMContentLoaded", function () {
    var galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 0,
        slidesPerView: 6,
        freeMode: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    })

    var galleryTop = new Swiper('.gallery-top', {
        spaceBetween: 10,
        loop: true,
        loopedSlides: 5, //looped slides should be the same
        thumbs: {
        swiper: galleryThumbs,
        },
        navigation: {
            nextEl: '.gallery-thumbs .next',
          },
    })
})