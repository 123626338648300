import {Controller} from 'scrollmagic'
import 'scrollmagic/scrollmagic/minified/plugins/animation.gsap.min'


export default {
    controller: new Controller(),
    isMenuOpen: false,
    modules: [],
    elementInDom: (selector) => {
        if (document.body.contains(document.querySelector(selector))) {
            return true
        } else {
            return false
        }
    },
    getElementContentWidth: (element) => {
        var styles = window.getComputedStyle(element);
        var padding = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight);

        return element.clientWidth - padding;
    },
    getElementPosition: (element) => {
        return element.getBoundingClientRect()
    },
    getCSSVariable: (variableName) => {
        return getComputedStyle(document.documentElement).getPropertyValue('--' + variableName)
    },
    setCSSVariable: (variableName, value) => {
        document.documentElement.style.setProperty("--" + variableName, value)
    },
    waitUntilElementExists: (selector, callback) => {
        const el = document.querySelector(selector);
        if (el) {
            return callback(el);
        }
        setTimeout(() => waitUntilElementExists(selector, callback), 500)
    },
    setScrollPosition: (elements, offset) => {
        var firstElement = $(elements).first(),
            position = firstElement[0].getBoundingClientRect().top + window.scrollY
        
        window.scroll({
            top: position - offset,
        })
    },
    infineteLoad: (config) => {
        var loadMoreButton = document.querySelector(config.buttonEl)

        // set the count of more listings with global variable
        // show more listings  
        var items = $(config.itemEl).slice(0, config.initCount)
        items.css({display: 'grid'})

        // Scroll to first listing
        // globals.setScrollPosition(listings, 320)

        $(loadMoreButton).on('click', (e) => { // click event for load more
            e.preventDefault()

            var hiddenItems = $(config.itemEl + ':hidden').slice(0, config.loadCount) // select next 10 hidden divs and show them
            $(hiddenItems).css({display: 'grid'})

            if ($(config.itemEl + ':hidden').length == 0) { // check if any hidden divs still exist
                $(loadMoreButton).hide()
            }
        })

        if ($(config.itemEl + ':hidden').length == 0) { // check if any hidden divs still exist
            $(loadMoreButton).hide()
        }
    }
}



// Mac Veneer font spacing hack
if (navigator.userAgent.match(/Macintosh/) || navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
    document.querySelectorAll('.heading-1, .heading-2, .heading-3, .btn-default').forEach((obj, index) => {
        obj.classList.add('is-mac')
    })
}
